import { CENTERContentTypeProps } from "@autoTypes";
import { Schema } from "@griddo/core";

export const CENTER: Schema.SimpleContentType<CENTERContentTypeProps> = {
	dataPacks: ["TESTIMONIAL", "NEWS", "EVENTS", "PROGRAMS", "LECTURERS"],
	title: "Centers",
	local: false,
	translate: true,
	clone: null,
	defaultValues: null,
	fromPage: false,
	schema: {
		fields: [
			{
				key: "title",
				title: "Title",
				placeholder: "Type a title",
				type: "TextField",
				mandatory: true,
			},
			{
				key: "shortTitle",
				title: "Short Title",
				placeholder: "Type a short title",
				type: "TextField",
			},
			// {
			// 	key: "order",
			// 	title: "Order",
			// 	helptext:
			// 		"A number (greater or equal than 10) indicating the order of this center in the program template filter",
			// 	type: "NumberField",
			// 	min: 10,
			// 	indexable: true,
			// },
			{
				type: "TextField",
				key: "computedOrder",
				title: "Computed order",
				computed: (simpleData) => {
					const centersOrder: { [key: string]: string } = {
						"16": "center10", // Ciencias Humanas y Sociales (Comillas CIHS)
						"07": "center11", // Teología (Comillas CIHS)
						"08": "center12", // Derecho Canónico (Comillas CIHS)
						"01": "center13", // Derecho (Comillas ICADE)
						"02": "center14", // Económicas y Empresariales (Comillas ICADE)
						"03": "center15", // Ingeniería (Comillas ICAI)
						"10": "center16", // Enfermería y Fisioterapia “San Juan de Dios”
						"13": "center17", // Migraciones
						"25": "center18", // Escuela Internacional de Doctorado
						"31": "center19", // Postgrado y Lifelong Learning
						"30": "center20", // MACC
						"29": "center21", // Advantere
						"28": "center22", // INEA
						"27": "center23", // CESAG
						"no-order": `center30${simpleData?.title?.toLowerCase() || ""}`,
					};

					return (
						centersOrder[simpleData?.idAPI || "no-order"] ||
						`center30${simpleData?.title?.toLowerCase() || ""}`
					);
				},
				indexable: true,
			},
			{
				key: "idAPI",
				title: "API ID",
				helptext: "Value of parameter centroId returned by the API.",
				type: "TextField",
			},
			{
				type: "RadioGroup",
				key: "color",
				title: "Color",
				options: [
					{ name: "comillas", title: "Comillas", value: "#FFB81C" },
					{ name: "ICADE", title: "ICADE", value: "#A4123F" },
					{ name: "ICAI", title: "ICAI", value: "#0E3E7A" },
					{ name: "EUEF", title: "EUEF", value: "#64B6AE" },
					{ name: "CIHS", title: "CIHS", value: "#7BA4DB" },
					{ name: "INEA", title: "INEA", value: "#506B1B" },
				],
			},
			{
				key: "logo",
				title: "Logo",
				type: "ImageField",
			},
		],
	},
};
