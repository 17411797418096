import { griddoDamDefaults, translations } from "@config";
import {
	SiteProvider as GriddoSiteProvider,
	SiteProviderProps as GriddoSiteProviderProps,
} from "@griddo/core";
import { Subthemes, Themes } from "@themes-setup";
import { GoToTop } from "@ui/elements";
import * as React from "react";

// Custom CSS
import "@themes/_custom/normalize.css";
import "@themes/_custom/global.css";
import "@themes/_custom/text-styles.css";
import "@themes/_custom/text-styles-onexed.css";
import "@themes/_custom/utilities.css";

// Themes
import "@themes/_css/global-theme.css";
import "@themes/_css/main-theme.css";
import "@themes/_css/euef-theme.css";
import "@themes/_css/icade-theme.css";
import "@themes/_css/icai-theme.css";
import "@themes/_css/cihs-theme.css";
import "@themes/_css/onexed-theme.css";
import "@themes/_css/inea-theme.css";

interface SiteProviderProps extends GriddoSiteProviderProps {
	theme: Themes;
	subtheme: Subthemes;
}

/**
 * `<SiteProvider>`
 *
 * This component is a wrapper to the Griddo <SiteProvider>. Must be exported and used to wrap the app/web.
 * This is a great place to add contexts like theme providers etc.
 */
function SiteProvider(props: SiteProviderProps) {
	const { children, ...rest } = props;
	const isEditor = rest.renderer === "editor" || rest.renderer === "preview";

	return (
		<GriddoSiteProvider
			griddoDamDefaults={griddoDamDefaults}
			translations={translations}
			{...rest}
		>
			<div
				id="___griddo"
				// Allows scroll in the Griddo editor preview area
				style={isEditor ? { height: "100%" } : {}}
			>
				<React.Suspense fallback={null}>
					{children}
					<GoToTop />
				</React.Suspense>
			</div>
		</GriddoSiteProvider>
	);
}

export { SiteProvider };
