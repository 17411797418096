import { NewsPinnedDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	animation,
	emptyState,
	getThemeOptions,
	heading,
	link,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<NewsPinnedDistributorProps> = {
	schemaType: "module",
	component: "NewsPinnedDistributor",
	category: "articlesAndEvents",
	displayName: "News Pinned Distributor",
	dataPacks: ["NEWS"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		QualityTemplate: ["mainSection"],
		ProgramsList: ["relatedContent"],
		ProgramLanding: ["mainSection"],
		Landing: ["mainSection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, mandatory: false, isMockup: true },
				{
					title: "Show News Abstract",
					type: "RadioGroup",
					key: "showNewsAbstract",
					mandatory: true,
					options: [
						{
							value: true,
							title: "Yes",
							name: "yes",
						},
						{
							value: false,
							title: "No",
							name: "no",
						},
					],
				},
				{
					...emptyState,
				},
				{
					title: "",
					type: "ReferenceField",
					sources: [{ structuredData: "NEWS" }],
					key: "data",
					maxItems: 19,
					mandatory: true,
					helptext: "Select up to 19 news articles.",
				},
				{ ...link, hideable: true },
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					...themeSelector,
					options: getThemeOptions([
						"default",
						"default-alt",
						"inverse",
						"accent-alt",
					]),
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "NewsPinnedDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h3",
		},
		emptyState:
			"Parece que por el momento no hay ninguna novedad... ¡Pero seguro que pronto las habrá! Visita nuestro portal de comunicación y prensa para no perderte nada.",
		showNewsAbstract: true,
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "newsDate-DESC",
			sources: [{ structuredData: "NEWS", globalOperator: "OR" }],
			quantity: 9,
			fullRelations: true,
		},
		link: {
			component: "Link",
		},
		anchorID: null,
		subtheme: "default",
		verticalSpacing: "medium",
		animation: "fadeIn",
	},

	thumbnails: {
		"onexed-theme": {
			"1x": "/thumbnails/modules/NewsPinnedDistributor/onexed@1x.png",
			"2x": "/thumbnails/modules/NewsPinnedDistributor/onexed@2x.png",
		},
		"1x": "/thumbnails/modules/NewsPinnedDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/NewsPinnedDistributor/thumbnail@2x.png",
	},
};

export default schema;
