import { CardCollectionProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import BasicCard from "@schemas/components/BasicCard";
import {
	additional,
	anchorID,
	animation,
	content,
	detail,
	getLinkModal,
	heading,
	linkModal,
	loremIpsumParagraph,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CardCollectionProps> = {
	schemaType: "module",
	component: "CardCollection",
	category: "collection",
	displayName: "Card Collection",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ConditionalField",
					title: "Intro Type",
					key: "introType",
					mandatory: true,
					options: [
						{
							name: "defaultIntro",
							value: "defaultIntro",
							title: "Default Intro",
						},

						{
							name: "institutionalIntro",
							value: "institutionalIntro",
							title: "Institutional Intro",
						},
					],
					fields: [
						{
							condition: "defaultIntro",
							...additional,
						},
						{
							condition: "defaultIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},

						{
							condition: "defaultIntro",
							...detail,
						},
						{
							condition: "defaultIntro",
							...content,
						},
						{
							condition: "institutionalIntro",
							title: "Logo",
							type: "ConditionalField",
							key: "currentLogo",
							hideable: true,
							options: [
								{
									value: "defaultLogo",
									title: "Default",
									name: "defaultLogo",
								},
								{
									value: "customLogo",
									title: "Custom",
									name: "customLogo",
								},
							],
							fields: [
								{
									condition: "defaultLogo",
									title: "Select Logo",
									type: "Select",
									key: "defaultLogo",
									placeholder: "Select a logo",
									mandatory: true,
									options: [
										{ value: "logoComillasHorizontalBn", label: "Comillas" },
										{
											value: "logoComillasHorizontal",
											label: "Comillas color",
										},
										{
											value: "logoComillasHorizontalInverse",
											label: "Comillas inverse",
										},
										{ value: "logoCihsHorizontalBn", label: "CIHS" },
										{ value: "logoCihsHorizontal", label: "CIHS color" },
										{
											value: "logoCihsHorizontalInverse",
											label: "CIHS inverse",
										},
										{ value: "logoEuefHorizontalBn", label: "EUEF" },
										{ value: "logoEuefHorizontal", label: "EUEF color" },
										{
											value: "logoEuefHorizontalInverse",
											label: "EUEF inverse",
										},
										{ value: "logoIcadeHorizontalBn", label: "ICADE " },
										{ value: "logoIcadeHorizontal", label: "ICADE color" },
										{
											value: "logoIcadeHorizontalInverse",
											label: "ICADE inverse",
										},
										{ value: "logoIcaiHorizontalBn", label: "ICAI" },
										{ value: "logoIcaiHorizontal", label: "ICAI color" },
										{
											value: "logoIcaiHorizontalInverse",
											label: "ICAI inverse",
										},
										{ value: "logoIneaBn", label: "INEA" },
										{ value: "logoInea", label: "INEA color" },
										{ value: "logoIneaInverse", label: "INEA INVERSE" },
									],
								},
								{
									condition: "customLogo",
									title: "Image",
									type: "ImageField",
									key: "customLogo",
									mandatory: true,
									helptext: "Size recommendation: 276x90px",
								},
							],
						},
						{
							condition: "institutionalIntro",
							...additional,
						},
						{
							condition: "institutionalIntro",
							...heading,
							isMockup: true,
							mandatory: false,
						},
						{
							condition: "institutionalIntro",
							...content,
						},
					],
				},
				{
					type: "ComponentArray",
					title: "Cards",
					key: "elements",
					elementUniqueSelection: true,
					whiteList: ["BasicCard", "BoxedCard", "BackgroundCard", "IconCard"],
					contentType: "components",
					reference: "kind",
				},
				{
					...linkModal,
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchorID },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CardCollection/Layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CardCollection/Layouts/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CardCollection/Layouts/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/CardCollection/Layouts/layout4.png",
						},
					],
				},
				{
					...themeSelector,
				},
				{
					title: "Card Style",
					key: "kind",
					type: "VisualUniqueSelection",
					elementUniqueSelection: true,
					reference: "elements",
					columns: 3,
					options: [
						{
							value: "BasicCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/basicCard.png",
						},
						{
							value: "BoxedCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/boxedCard.png",
						},
						{
							value: "BackgroundCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/backgroundCard.png",
						},
						{
							value: "IconCard",
							img: "/thumbnails/modules/CardCollection/CardStyle/iconCard.png",
						},
					],
				},
				{ ...verticalSpacing },
				{ ...animation },
			],
		},
	],

	default: {
		component: "CardCollection",
		introType: "defaultIntro",
		additional: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: loremIpsumParagraph,
		content: loremIpsumParagraph,
		currentLogo: "defaultLogo",
		defaultLogo: "logoComillasHorizontalBn",
		elements: [
			{ ...BasicCard.default },
			{ ...BasicCard.default },
			{ ...BasicCard.default },
		],
		link: getLinkModal("Ver más"),
		layout: "layout2",
		kind: "BasicCard",
		subtheme: "default",
		anchorID: null,
		verticalSpacing: "medium",
		animation: "none",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CardCollection/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CardCollection/thumbnail@2x.png",
	},
};

export default schema;
